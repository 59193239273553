.heading-menu {
    width: 100%;
    height: $topbar-height;
    z-index: 100;
}

.root-container {
    display: flex;
    align-items: stretch;
    align-content: stretch;
    background-color: color("shades", "grey");
}

.main {
    flex-basis: 100%;
    min-height: calc(100vh - #{$topbar-height});

    .sidebar ~ & {
        flex-basis: calc(100% - #{$sidebar-width});
        overflow-x: auto;
    }
    & > .container {
        padding-top: $spacing-unit * 3;

        & + .page {
            margin-top: -$spacing-unit * 2;
        }
    }
}

.sidebar {
    flex-basis: $sidebar-width;
    min-height: calc(100% - #{$topbar-height});
}
