$calendar-header-height: 60px;
$calendar-day-height: 130px;
$calendar-border-color: color("grey", "300");

.calendrier {
    display: flex;

    &-filters {
        &-event {
            display: flex;
            align-items: stretch;

            &:before {
                content: "";
                width: 7px;
                margin-right: 7px;
            }

            &--tirage:before {
                background-color: color("primary", "base");
            }
            &--consolidation:before {
                background-color: color("red", "base");
            }
            &--prorogation:before {
                background-color: color("teal", "base");
            }
            &--remboursement_anticipe:before {
                background-color: color("grey", "base");
            }
            &--changement_indice:before {
                background-color: color("green", "base");
            }
            &--passage_taux_fixe:before {
                background-color: color("pink", "base");
            }
            &--changement_marge:before {
                background-color: color("purple", "base");
            }
            &--respect_covenant:before {
                background-color: color("orange", "base");
            }
            &--capitalisation:before {
                background-color: color("yellow", "base");
            }
            &--remboursement:before {
                background-color: color("blue", "base");
            }
        }
    }

    &-grid {
        flex-grow: 1;
        width: 100%;
        margin-left: calc(2 * #{$spacing-unit});
    }

    &-header {
        display: flex;
        justify-content: space-between;

        &--cell {
            width: 100%;
            font-weight: bold;
            height: $calendar-header-height;
            display: flex;
            justify-content: center;
            align-items: center;
            color: color("shades", "black");

            & + & {
                border-left: 1px solid $calendar-border-color;
            }
        }
    }

    &-month {
        width: 100%;
        background-color: color("grey", "light");
    }

    &-week {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: color("grey", "light");
    }

    &-day {
        width: 100%;
        padding: 5px;
        height: $calendar-day-height;
        border-top: 1px solid $calendar-border-color;
        background-color: color("shades", "grey");
        position: relative;

        & + & {
            border-left: 1px solid $calendar-border-color;
        }

        &--full {
            height: unset;
            min-height: $calendar-day-height;
        }

        &--header {
            display: flex;
        }

        &--holiday {
            margin-bottom: 10px;
        }

        &--number {
            margin-left: auto;
        }

        &--out-of-month {
            background-color: color("grey", "300");
        }
    }

    &-event {
        margin-bottom: 10px;
        height: 25px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        &:before {
            content: "";
            display: block;
            width: 7px;
            height: 100%;
            margin-right: 7px;
        }

        &--tirage:before {
            background-color: color("primary", "base");
        }
        &--consolidation:before {
            background-color: color("red", "base");
        }
        &--prorogation:before {
            background-color: color("teal", "base");
        }
        &--remboursement_anticipe:before {
            background-color: color("grey", "base");
        }
        &--changement_indice:before {
            background-color: color("green", "base");
        }
        &--passage_taux_fixe:before {
            background-color: color("pink", "base");
        }
        &--changement_marge:before {
            background-color: color("purple", "base");
        }
        &--respect_covenant:before {
            background-color: color("orange", "base");
        }
        &--capitalisation:before {
            background-color: color("yellow", "base");
        }
        &--remboursement:before {
            background-color: color("blue", "base");
        }
    }

    &-tooltip {
        position: absolute;
        z-index: 20;
        border-radius: $border-radius;
        background-color: color("shades", "white");
        border: 1px solid $calendar-border-color;
        padding: $spacing-unit $spacing-unit;

        &--month,
        &--week {
            width: 150%;
        }

        &--day {
            width: 50%;
            left: 25%;
        }

        &--left {
            right: 100%;
        }

        &--right {
            left: 100%;
        }

        &--top {
            top: 0;
        }

        &--bottom {
            bottom: 0;
        }

        &-header {
            display: flex;
            align-items: center;
            position: relative;
            height: 28px;

            &-title {
                font-size: 16px;
                font-weight: bold;
            }
        }

        &-close {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            width: 28px;
            height: 28px;
            border-radius: 28px;
            background: transparent;
            border: 2px solid color("grey", "base");

            &::after,
            &::before {
                content: "";
                display: block;
                height: 80%;
                width: 2px;
                background-color: color("grey", "base");
                position: absolute;
                top: 10%;
                left: calc(50% - 1px);
            }

            &::before {
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }

            @include event_attention() {
                border: 2px solid darken(color("grey", "base"), 10);
                text-decoration: none;
                outline-width: 0;
                &::after,
                &::before {
                    background-color: darken(color("grey", "base"), 10);
                }
            }
        }
    }

    &-table {
        margin-top: $spacing-unit;

        .table__col:first-child {
            text-align: right;
            font-weight: bold;
            color: color("shades", "black");
        }
    }

    &_data {
        padding: $spacing-unit * 2;
        background-color: color("shades", "white");
        border-radius: $border-radius;
        box-shadow: $box-shadow;

        .chart-tooltip__item-value {
            text-align: right;
        }
    }
}
