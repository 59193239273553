.field {
    &-control {
        &__textarea {
            display: block;
            width: 100%;
            height: 100%;
            min-height: 7rem;
            max-height: 24rem;
            padding: $spacing-unit * 1.5 $spacing-unit * 2;
            font-size: 1.3rem;
            line-height: 1.7rem;
            background-color: transparent;
            border: none;
            resize: none;

            &::placeholder {
                color: color("shades", "white");
            }
            &:focus {
                outline: 0;

                &::placeholder {
                    color: color("grey", "dark");
                }
            }
            &.disabled {
                color: color("grey", "disabled-text");
            }
        }
    }
}

.form-contrast {
    .field-control__textarea {
        color: color("shades", "white");

        &::placeholder {
            color: color("secondary", "base");
        }
        &.disabled,
        &:disabled {
            color: color("primary", "base");
        }
    }
}
