.field {
    &__repeater {
        &-container {
            display: flex;
            justify-content: space-between;

            & > * {
                margin: 0 $spacing-unit / 2;

                &:first-of-type {
                    margin-left: 0;
                }
                &:last-of-type {
                    margin-right: 0;
                }
            }
            &--left {
                @extend .field-control;
                width: calc(100% - 10px);
                padding: #{$spacing-unit * 1.5} 0 $spacing-unit / 2;

                &.focus,
                &:focus-within {
                    border-color: color("grey", "300");
                    outline: 0;

                    & > .field__label {
                        color: color("grey", "dark");
                    }
                }
                &.error {
                    border-color: color("red", "base");
                }
                &.is-empty {
                    cursor: pointer;
                }
            }
        }
        &-field {
            display: flex;
            align-items: center;
            padding: 0 $spacing-unit;

            .field__wrapper {
                width: 100%;
            }
            & > * {
                margin: 0 $spacing-unit / 2;

                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            .btn--icon {
                min-width: 24px;
            }
        }
        &-page-content {
            .field__repeater-container--left {
                .field__repeater-fields {
                    .field__repeater-field {
                        align-items: flex-start;

                        .btn--icon {
                            margin-top: $spacing-unit / 2;
                        }
                        .field__wrapper {
                            &:first-of-type {
                                width: 20%;
                            }
                            &:last-of-type {
                                width: 100%;
                            }
                            textarea {
                                min-height: 300px;
                            }
                        }
                    }
                }
            }
        }
    }

    &-contrat-lenders .field__repeater-container--left {
        .field__wrapper:first-of-type {
            width: 60%;
        }
        .field__wrapper:last-of-type {
            width: 40% !important;
        }
    }
}

.field {
    &.has-radio-inline {
        .field__repeater-container {
            .field-control-radio {
                @extend .field-control-radio.show-inline;
            }
        }
    }
    &.has-percent {
        .field__repeater-field {
            .field__wrapper:last-of-type {
                width: 100px;
            }
        }
    }
    &.is-others {
        button {
            visibility: hidden;
        }
        .field__repeater-container--left {
            padding: 5px 0;
            border: 0;
        }
    }
}
