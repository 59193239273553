fieldset,
.fieldset-like {
    margin: $spacing-unit 0 $spacing-unit * 2;
    padding: 0;
    border: 0;
    border-top: 1px solid color("grey", "300");
}

legend,
.legend-like {
    margin: $spacing-unit * 2 0;
    padding: 0 $spacing-unit * 3;
    font-size: 1.5rem;
    line-height: 1.8rem;
    text-align: center;
    color: color("secondary", "base");
    background-color: color("shades", "white");
}

.fieldset-like {
    position: relative;
    margin-top: $spacing-unit * 4;
    padding-top: $spacing-unit * 3;
}
.legend-like {
    position: absolute;
    top: -28px;
    left: 50%;
    transform: translateX(-50%);
}
