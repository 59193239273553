.section {
    display: block;
    //min-width: 100%;
    padding: #{$spacing-unit * 2} #{$spacing-unit * 4};
    margin-bottom: 30px;
    transition: width 1s;
    &-title {
        font-family: $font-inter;
        font-weight: bold;
        color: color("shades", "black");
        margin: 0 0 1rem 0;
    }

    &::after {
        display: block;
        content: "";
        clear: both;
    }
}
