.grille {
    @for $i from 1 through 5 {
        &.grille-gutter--#{$i} {
            & > * {
                margin-bottom: #{$spacing-unit * $i};
            }
        }
    }

    @include media-breakpoint-up("medium") {
        display: flex;
        justify-content: space-between;

        & > * {
            width: 100%;
            margin-left: $spacing-unit;
            margin-bottom: $spacing-unit;

            &:first-child {
                margin-left: 0;
            }
        }

        @for $i from 1 through 5 {
            &.grille-gutter--#{$i} {
                & > * {
                    margin-left: #{$spacing-unit * $i};

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        & .grille {
            @for $i from 1 through 11 {
                &-#{$i} {
                    flex: $i;
                    width: auto;
                }
            }
        }
    }
}

.grille-large {
    @for $i from 1 through 5 {
        &.grille-gutter--#{$i} {
            & > * {
                margin-bottom: #{$spacing-unit * $i};
            }
        }
    }

    @include media-breakpoint-up("large") {
        display: flex;
        justify-content: space-between;

        & > * {
            width: 100%;
            margin-left: $spacing-unit;
            margin-bottom: $spacing-unit;

            &:first-child {
                margin-left: 0;
            }
        }

        @for $i from 1 through 5 {
            &.grille-gutter--#{$i} {
                & > * {
                    margin-left: #{$spacing-unit * $i};

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        & .grille {
            @for $i from 1 through 11 {
                &-#{$i} {
                    flex: $i;
                    width: auto;
                }
            }
        }
    }
}
