.financements__saisie__form {
    .hidden {
        display: none;
    }
}

.options-clauses-tabs-container {
    text-align: center;
}

.options-clauses-tabs {
    padding: 20px;
    margin: 10px;
    white-space: normal;
    height: 90px;
}

.options-clauses-tabs,
.options-clauses-tabs:hover,
.options-clauses-tabs:active,
.options-clauses-tabs:focus {
    background-color: color("secondary", "base");
}

.options-clauses-tabs.clicked,
.options-clauses-tabs.clicked:hover {
    background-color: #58a8ff;
}
