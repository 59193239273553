.svg-inline--fa {
    .fa-secondary {
        opacity: 1 !important;
    }
    &.svg-inline--active {
        .fa-primary {
            color: color("secondary", "base");
        }
        &.fa-arrows,
        &.fa-file-pdf {
            .fa-primary {
                color: color("teal", "base");
            }
        }
        &.fa-bell,
        &.fa-cog {
            .fa-primary {
                color: color("red", "base");
            }
        }
        &.fa-sliders-v,
        &.fa-circle,
        &.fa-arrows {
            .fa-secondary {
                color: color("red", "base");
            }
        }
    }
    &.fa-exclamation-triangle {
        color: color("orange", "base");
    }
}
