.chart {
    &__legend {
        font-size: 1.3rem;

        &-text {
            fill: color("shades", "black");
        }
        &-background {
            width: 100%;
            fill: color("shades", "grey");
        }
        &-pie {
            text-anchor: middle;

            &-inner {
                font-weight: bold;
                fill: color("shades", "white");
                text-anchor: middle;
            }
        }
        &-donut {
            text-anchor: middle;

            &-value {
                text-anchor: middle;
                alignment-baseline: middle;
            }
            &-label {
                text-anchor: middle;
                alignment-baseline: middle;
            }
        }
    }
    &__total {
        &-background {
            fill: color("shades", "grey");
        }
    }
}
