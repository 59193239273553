.analyse {
    &_filters {
        @extend .form-contrast;

        $baseClass: &;
        padding-bottom: $spacing-unit * 7;
        margin-bottom: -$spacing-unit * 7;
        background-color: color("secondary", "base");
        display: flex;
        align-items: center;

        &_form {
            display: flex;
            flex-direction: column;
        }

        &_fields {
            margin: $spacing-unit 0;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            margin: 10px 0;

            &.inline {
                display: inline-block;
            }

            .field-control-select__selected_item {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 85%;
            }
        }
        .grid {
            margin: 0 !important;
        }
        .field {
            flex-direction: row;
            align-items: center;
            width: auto;
            margin: 0 $spacing-unit * 2 0 0;
        }
        &_periods {
            position: relative;
            min-height: 320px;
            margin-bottom: $spacing-unit * 3;

            table {
                width: 100%;

                td {
                    padding: $spacing-unit / 2;
                }
            }
        }

        &_button {
            margin-left: 50px;
        }
    }
}
