.sidebar {
    position: relative;
    margin: 0;
    padding: 0;
    min-height: calc(100% - #{$topbar-height});
    background-color: color("shades", "white");
    z-index: 100;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);

    &__nav,
    &__nav_child {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    &__link {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        text-decoration: none;
        cursor: pointer;

        a {
            text-decoration: none;
        }
    }
    &__nav {
        margin-bottom: #{$spacing-unit * 24};

        .sidebar__nav_parent {
            display: flex;
            align-items: center;
            padding: #{$spacing-unit * 3};
            border-left: 4px solid color("shades", "white");
            cursor: pointer;

            .sidebar__link_icon {
                display: inline-block;
                height: #{$spacing-unit * 3};
                width: #{$spacing-unit * 3};
                margin-right: #{$spacing-unit * 2};
                padding: 7px 9px;
                color: color("shades", "black");
                background-image: none;
                border-radius: 50%;
            }
            &.active {
                border-left: 4px solid color("secondary", "base");
                background-color: color("shades", "grey");

                .sidebar__link {
                    color: color("shades", "black");

                    &_icon {
                        color: color("shades", "white");
                        background-image: linear-gradient(
                            to right bottom,
                            color("secondary", "base"),
                            color("primary", "base")
                        );
                    }
                }
            }
            @include event_attention() {
                @extend .active;
            }
        }
        .sidebar__nav_child {
            padding: $spacing-unit 0;

            .sidebar__item {
                padding: #{$spacing-unit * 1.5} #{$spacing-unit * 2} #{$spacing-unit * 1.5} $spacing-unit;
                padding-right: #{$spacing-unit * 2};
                margin-left: #{$spacing-unit * 7.5};

                a {
                    color: color("grey", "disabled");
                }
                &.hover,
                &.active {
                    a {
                        color: color("secondary", "base");
                    }
                }
                &.active {
                    position: relative;

                    &:before {
                        position: absolute;
                        left: -#{$spacing-unit * 3};
                        content: "\2022";
                        font-size: 2rem;
                        color: color("secondary", "base");
                    }
                }
                @include event_active() {
                    @extend .active;
                }
                @include event_hover() {
                    @extend .hover;
                }
            }
        }
    }

    &__copyright {
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        margin: 0;
        padding: #{$spacing-unit * 3};

        p {
            margin: 0;
        }
    }
}
