.field {
    &-control-wrapper {
        position: relative;

        &[aria-expanded="true"] {
            .field-control {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            .field__label {
                color: color("secondary", "base");
            }
        }
    }
    &-control {
        position: relative;
        border: 1px solid color("grey", "300");
        border-radius: $border-radius;

        &.disabled {
            color: color("grey", "disabled-text");

            input,
            select,
            textarea {
                cursor: not-allowed;
            }
            .field-control__adornment {
                color: color("grey", "disabled-text");
            }
        }
        &.focus,
        &:focus-within {
            border-color: color("secondary", "base");
            outline: 0;

            & > .field__label {
                color: color("secondary", "base");
            }
        }
        &.error {
            border: 1px solid color("red", "base");

            &.focus,
            &.field-control--focus,
            &:focus-within {
                outline: 0;
                border: 1px solid color("red", "base");

                .field__label {
                    color: color("grey", "dark");
                }
            }
            .field-control-file__preview {
                border-color: color("red", "base");
            }
        }
        &.percent {
            .field-control__input {
                text-align: right;
                padding-right: 40px;
            }
        }
    }
}

.form-contrast {
    .field-control {
        border-color: color("grey", "400");

        &.focus,
        &:focus-within {
            border-color: color("shades", "white");

            & > .field__label {
                color: color("shades", "white");
            }
        }
        &.disabled {
            border-color: color("primary", "base");
            background: transparent;
        }
        &.error {
            border-color: color("red", "base");

            &.focus,
            &.field-control--focus,
            &:focus-within {
                border-color: color("red", "base");
            }
        }
    }
}
