.field {
    &-control {
        &__adornment {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.3rem;
            line-height: 1.7em;
            color: color("shades", "black");

            &--start {
                left: $spacing-unit * 2;
            }
            &--end {
                right: $spacing-unit * 2;
            }
            .percentage {
                position: absolute;
                top: 50%;
                right: -10px;
                padding: 5px 10px;
                transform: translateY(-50%);
                background: color("shades", "white");
            }
        }
    }
}

.form-contrast {
    .field-control__adornment {
        color: color("shades", "white");

        .percentage {
            background: color("secondary", "base");
        }
    }
}
