.field__interval {
    @extend .field-control;
    margin: $spacing-unit 0;
    padding: $spacing-unit * 1.5 $spacing-unit / 2 $spacing-unit;

    &__items {
        display: flex;

        & > * {
            margin: 0 $spacing-unit / 2;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .field__label {
        @extend .label-on-top;
    }
}
