.analyse_graph {
    $baseClass: &;

    &_wrapper {
        &.side-title {
            @include media-breakpoint-up("large") {
                display: flex;
                align-items: stretch;
                align-content: stretch;
                margin-bottom: #{$spacing-unit * 3};
            }
            & > * {
                flex: 0 0 auto;
            }
            & #{$baseClass}_header {
                @include media-breakpoint-up("large") {
                    position: relative;
                    width: 18%;
                    margin-right: $spacing-unit * 2;
                    padding: 0 20px;
                    text-align: center;
                    color: color("grey", "dark");
                    background: color("grey", "100");
                }

                h3 {
                    @include media-breakpoint-up("large") {
                        position: relative;
                        margin-bottom: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            & #{$baseClass}_content {
                @include media-breakpoint-up("large") {
                    width: 82%;
                }
            }
        }
    }
    &_toggle {
        display: flex;
        justify-content: flex-end;
        margin-bottom: $spacing-unit;
        cursor: pointer;

        .svg-inline--active {
            color: color("secondary", "base");
        }
        & > * {
            margin-left: #{$spacing-unit * 2};
        }
    }
    &_charts {
        &--double {
            @include media-breakpoint-up("medium") {
                display: flex;
            }
        }
        &_container {
            width: 100%;
            font-size: 1rem;
        }
    }
    &_data_before {
        display: flex;

        & > * {
            flex: 1;
            margin: 0 #{$spacing-unit * 2};
            padding: #{$spacing-unit * 3};
            text-align: center;
            font-size: 1.2rem;
            font-weight: bold;
            color: color("grey", "dark");
            background: color("grey", "100");
        }
    }
    &_days {
        @extend h3;
        margin: $spacing-unit 0;
        text-align: center;

        strong {
            color: color("red", "dark");
        }
    }
    &_nn1 {
        h3 {
            font-size: 1.3rem;
        }
        @include media-breakpoint-up("large") {
            .analyse_graph_wrapper.side-title .analyse_graph_header {
                width: 150px;
                margin-right: 0;
            }
        }
    }
}
