.field {
    &-control {
        &-radio {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            width: 100%;
            padding: $spacing-unit * 1.5 $spacing-unit * 2 $spacing-unit;
            border: 1px solid color("grey", "300");
            border-radius: $border-radius;

            &__group {
                position: relative;
                display: inline-flex;
                align-items: center;
                cursor: pointer;
            }

            &__input {
                top: 0;
                left: 0;
                width: 100%;
                cursor: inherit;
                height: 100%;
                margin: 0;
                opacity: 0;
                padding: 0;
                z-index: 1;
                position: absolute;

                @include event_attention() {
                    ~ .field-control-radio__label:before {
                        border-color: lighten(color("secondary", "base"), 10%);
                    }
                }

                &.focus {
                    ~ .field-control-radio__label:before {
                        border-color: lighten(color("secondary", "base"), 10%);
                    }
                }

                &:checked {
                    ~ .field-control-radio__label {
                        &:before {
                            border-color: color("secondary", "base");
                        }

                        &:after {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
            }

            &.show-inline {
                flex-direction: row;

                .field-control-radio__group {
                    margin-right: $spacing-unit * 2;
                }
            }

            &__label {
                position: relative;
                min-height: 1.5em;
                padding-left: 1.5em;
                display: inline-flex;
                align-items: center;

                &:before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0;
                    display: block;
                    width: 12px;
                    height: 12px;
                    background-color: transparent;
                    border: color("grey", "base") solid 1px;
                    border-radius: 100%;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 7px;
                    left: 3px;
                    display: block;
                    width: 6px;
                    height: 6px;
                    background-color: color("secondary", "base");
                    border-radius: 100%;
                    opacity: 0;
                    transform: scale(0);
                    transition: all 0.15s ease-in-out;
                }
            }

            &.disabled {
                .field-control-radio__group {
                    cursor: not-allowed;
                }

                .field-control-radio__input {
                    @include event_attention() {
                        ~ .field-control-radio__label:before {
                            border-color: color("grey", "base");
                        }
                    }

                    &:checked {
                        ~ .field-control-radio__label {
                            &:before {
                                border-color: color("grey", "base");
                            }
                        }
                    }
                }
                .field-control-radio__label {
                    color: color("grey", "disabled-text");

                    &:after {
                        background-color: color("grey", "disabled-text");
                    }
                    &:before {
                        border-color: color("grey", "base");
                    }
                }
            }

            &.error {
                border: 1px solid color("red", "base");

                .field-control-radio__input {
                    @include event_attention() {
                        ~ .field-control-radio__label:before {
                            border-color: color("red", "base");
                        }
                    }

                    &--focus {
                        ~ .field-control-radio__label:before {
                            border-color: color("red", "base");
                        }
                    }

                    &:checked {
                        ~ .field-control-radio__label {
                            &:before {
                                border-color: color("red", "base");
                            }
                        }
                    }
                }
                .field-control-radio__label {
                    color: color("red", "base");

                    &:after {
                        background-color: color("red", "base");
                    }
                    &:before {
                        border-color: color("red", "base");
                    }
                }
            }
            &--inline {
                flex-direction: row;
                flex-wrap: wrap;

                .field-control-radio__group {
                    margin-right: $spacing-unit * 2;
                }
            }
            &--no-border {
                border: 0;
                padding: 0;
                padding-top: 5px;
            }
        }
    }
}

.form-contrast {
    .field-control-radio {
        &__input {
            @include event_attention() {
                ~ .field-control-radio__label:before {
                    border-color: color("shades", "white");
                }
            }
            &.focus {
                ~ .field-control-radio__label:before {
                    border-color: color("shades", "white");
                }
            }
            &:checked {
                ~ .field-control-radio__label {
                    &:before {
                        border-color: color("shades", "white");
                    }
                }
            }
        }
        &__label {
            color: color("shades", "white");

            &:before {
                border-color: color("grey", "base");
            }
            &:after {
                background-color: color("shades", "white");
            }
        }
        &.disabled {
            border-color: color("primary", "base");

            .field-control-radio__input {
                @include event_attention() {
                    ~ .field-control-radio__label:before {
                        border-color: color("primary", "base");
                    }
                }
                &:checked {
                    ~ .field-control-radio__label {
                        &:before {
                            border-color: color("primary", "base");
                        }
                    }
                }
            }
            .field-control-radio__label {
                color: color("primary", "base");

                &:after {
                    background-color: color("primary", "base");
                }
                &:before {
                    border-color: color("primary", "base");
                }
            }
        }
    }
}
