.analyse_compare {
    @include media-breakpoint-up("medium") {
        display: flex;
        margin-bottom: #{$spacing-unit * 2};
    }
    & > * {
        flex: 0 0 auto;

        &:first-child {
            width: 0%;

            @include media-breakpoint-up("large") {
                width: 150px;
            }
        }
    }
    &_dates {
        width: 100%;

        @include media-breakpoint-up("medium") {
            display: flex;
            justify-content: space-between;
        }
        @include media-breakpoint-up("large") {
            width: 82%;
        }

        & > * {
            flex: 1;
            margin: 0 0 20px;
            padding: 20px;
            text-align: center;
            color: color("grey", "dark");
            background: color("grey", "100");

            @include media-breakpoint-up("medium") {
                margin: 0 16px 0 16px;
            }

            h3 {
                margin-bottom: 0;
            }
        }
    }
}
