.field {
    position: relative;
    width: 100%;
    margin: 5px 0;
}

.field-hidden {
    margin: 0;

    .field-control {
        border: none;
    }
}

.error {
    scroll-margin: 80px;
}
