.position {
    &--relative {
        position: relative;
    }

    &--absolute {
        position: absolute;
    }

    &--sticky {
        position: sticky;
    }
}
