.field {
    &-control {
        &-checkbox {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            width: 100%;
            padding: $spacing-unit * 1.5 $spacing-unit * 2 $spacing-unit;
            border: 1px solid color("grey", "300");
            border-radius: $border-radius;

            &__group {
                position: relative;
                display: inline-flex;
                align-items: center;
                cursor: pointer;
            }

            &__input {
                top: 0;
                left: 0;
                width: 100%;
                cursor: inherit;
                height: 100%;
                margin: 0;
                opacity: 0;
                padding: 0;
                z-index: 1;
                position: absolute;

                @include event_attention() {
                    ~ .field-control-checkbox__label:before {
                        border-color: lighten(color("secondary", "base"), 10%);
                    }
                }

                &.focus {
                    ~ .field-control-checkbox__label:before {
                        border-color: lighten(color("secondary", "base"), 10%);
                    }
                }

                &:checked {
                    ~ .field-control-checkbox__label {
                        &:before {
                            border-color: color("secondary", "base");
                        }

                        &:after {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                    ~ .svg-inline--fa {
                        opacity: 1;
                        transform: scale(1);
                    }
                }
            }

            &__label {
                position: relative;
                min-height: 1.5em;
                padding-left: 1.5em;
                display: inline-flex;
                align-items: center;

                &:before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0;
                    display: block;
                    width: 12px;
                    height: 12px;
                    background-color: transparent;
                    border: color("grey", "base") solid 1px;
                    border-radius: $border-radius / 2;
                }
            }
            .svg-inline--fa {
                position: absolute;
                top: 7px;
                left: 3px;
                font-size: 0.6rem;
                color: color("secondary", "base");
            }

            &.disabled {
                .field-control-checkbox__group {
                    cursor: not-allowed;
                }

                .field-control-checkbox__input {
                    @include event_attention() {
                        ~ .field-control-checkbox__label:before {
                            border-color: color("grey", "base");
                        }
                    }

                    &:checked {
                        ~ .field-control-checkbox__label {
                            &:before {
                                border-color: color("grey", "base");
                            }
                        }
                        ~ .svg-inline--fa {
                            color: color("grey", "disabled-text");
                        }
                    }
                }
                .field-control-checkbox__label {
                    color: color("grey", "disabled-text");

                    &:before {
                        border-color: color("grey", "base");
                    }
                }
            }

            &.error {
                border: 1px solid color("red", "base");

                .field-control-checkbox__input {
                    @include event_attention() {
                        ~ .field-control-checkbox__label:before {
                            border-color: color("red", "base");
                        }
                    }

                    &.focus {
                        ~ .field-control-checkbox__label:before {
                            border-color: color("red", "base");
                        }
                    }

                    &:checked {
                        ~ .field-control-checkbox__label {
                            &:before {
                                border-color: color("red", "base");
                            }
                        }
                        ~ .svg-inline--fa {
                            color: color("red", "base");
                        }
                    }
                }
                .field-control-checkbox__label {
                    color: color("red", "base");

                    &:before {
                        border-color: color("red", "base");
                    }
                }
            }
            &--columns {
                flex-direction: row;
                flex-wrap: wrap;

                .field-control-checkbox__group {
                    flex: 50%;
                }
            }
            &.with-modal {
                .field-control-checkbox {
                    &__group {
                        cursor: default;
                    }
                    &__input {
                        width: auto;
                    }
                    &__label.is-checked {
                        cursor: pointer;
                    }
                    &__edit-icon {
                        cursor: pointer;

                        .svg-inline--fa {
                            position: relative;
                            top: initial;
                            font-size: 1.3rem;
                            line-height: 1.7em;
                            color: color("shades", "black");
                        }
                    }
                }
            }
        }
    }
}

.form-contrast {
    .field-control-checkbox {
        &__input {
            @include event_attention() {
                ~ .field-control-checkbox__label:before {
                    border-color: color("shades", "white");
                }
            }

            &.focus {
                ~ .field-control-checkbox__label:before {
                    border-color: color("shades", "white");
                }
            }

            &:checked {
                ~ .field-control-checkbox__label:before {
                    border-color: color("shades", "white");
                }
            }
        }

        &__label {
            color: color("shades", "white");
        }
        .svg-inline--fa {
            color: color("shades", "white");
        }
        &.disabled {
            border-color: color("primary", "base");

            .field-control-checkbox__input {
                @include event_attention() {
                    ~ .field-control-checkbox__label:before {
                        border-color: color("primary", "base");
                    }
                }

                &:checked {
                    ~ .field-control-checkbox__label {
                        &:before {
                            border-color: color("primary", "base");
                        }
                    }
                    ~ .svg-inline--fa {
                        color: color("primary", "base");
                    }
                }
            }
            .field-control-checkbox__label {
                color: color("primary", "base");

                &:before {
                    border-color: color("primary", "base");
                }
            }
        }
    }
}
