.chart__tooltip,
.chart-tooltip {
    position: absolute;
    top: 0;
    min-width: 269px;
    padding: $spacing-unit * 2;
    background-color: color("shades", "white");
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    z-index: 10;

    .img-responsive {
        height: $spacing-unit * 4;
        margin-bottom: $spacing-unit * 2;
    }

    &__title {
        margin-bottom: $spacing-unit * 2;
        font-weight: bold;
    }

    &__list {
        padding: 0;
        margin: 0;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        padding: 0;
        margin: 0 0 $spacing-unit;
        list-style: none;

        &:last-of-type {
            margin-bottom: 0;
        }
        + & {
            margin-top: 2000;
        }
        &-separator {
            flex-grow: 1;
            margin: 0 $spacing-unit 4px;
            border-bottom: 1px dashed color("grey", "base");
        }

        &--total {
            margin: $spacing-unit * 2 -#{$spacing-unit} -#{$spacing-unit} !important;
            padding: $spacing-unit;
            background: color("shades", "grey");
        }
        &--all-in {
            color: color("red", "base");
        }
    }
}
