.table-analyse {
    .table {
        &__row {
            border: 0;

            &--small {
            }
            &--toggle {
                cursor: pointer;
            }
            &--total {
                background-color: color("shades", "grey");

                .table__col {
                    padding: $spacing-unit;
                    color: color("shades", "black");
                    font-size: 1.3rem;

                    &--toggle {
                        padding: 0;
                    }
                }
            }
        }
        &__col {
        }
        &__toggle {
            font-size: 0.6rem;
            color: color("shades", "black");

            .fa-caret-down.up {
                transform: rotate(180deg);
            }
        }
        &__head,
        &__body,
        &__foot {
            border: 10px solid transparent;
            border-width: 10px 20px 20px;
        }
        &__head {
            border-width: 10px 20px 10px;

            .table {
                &__row {
                }
                &__col {
                    padding: $spacing-unit;
                    padding-bottom: 0;
                    font-size: 1.3rem;
                    font-weight: normal;
                    color: color("grey", "dark");

                    &--toggle {
                        width: 1px;
                        padding: 0;
                    }
                    &--small {
                        max-width: 100px;
                    }
                    &--no-wrap {
                        white-space: nowrap;
                    }
                    &--border-left {
                        border-left: 1px solid color("grey", "100");
                    }
                    &--border-right {
                        border-right: 1px solid color("grey", "100");
                    }
                }
            }
        }
        &__body {
            .table {
                &__col {
                    padding: $spacing-unit;
                    font-size: 1.4rem;
                    color: color("primary", "dark");

                    &.has-logo {
                        height: 100%;
                        padding: 2px $spacing-unit;

                        .img-responsive {
                            max-height: 28px;
                        }
                    }
                    &--toggle {
                        width: 1px;
                        padding: 0;
                    }
                    &--no-wrap {
                        white-space: nowrap;

                        & > div {
                            white-space: nowrap;
                        }
                    }
                }
                &__row {
                    &:first-child {
                        border: 0;
                    }
                    &--small {
                        .table__col {
                            text-align: left;
                            color: color("grey", "dark");
                            background-color: color("shades", "white");

                            &:not(.table__col--toggle) {
                                padding: 5px 10px;
                            }
                        }
                    }
                    &--group {
                        .table__col {
                            background: color("shades", "white");
                            color: color("primary", "dark");
                            font-weight: normal;
                        }
                    }
                }
            }
        }
        &__head,
        &__body {
            .table__col:nth-of-type(1) {
                padding-left: 10px;
                padding-right: 20px;
            }
        }
        &__foot {
            border-color: color("shades", "grey");
            border-width: 11px 20px;

            .table__col {
                &--no-wrap {
                    white-space: nowrap;

                    & > div {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
    &.has-subgroups {
        .table__body {
            .table__row {
                &--group {
                    border-top: 1px solid color("grey", "base");

                    .table__col {
                        color: color("primary", "dark");
                    }
                }
                &--toggle {
                    .table__col {
                        color: color("shades", "black");
                    }
                }
            }
        }
    }
}
