$primary: (
    "base": #2babe2,
    "dark": #007cb0,
);

$secondary: (
    "base": #0871b8,
    "dark": #004788,
);

$red: (
    "base": #dc2321,
    "dark": #c03f36,
);

$teal: (
    "base": #0ae1c4,
    "dark": #02c4aa,
);

$grey: (
    "base": #c0c3cd,
    "light": #f7f7fb,
    "dark": #9191a4,
    "disabled": #abb3b9,
    "disabled-text": #545454,
    "50": #f7f6fb,
    "100": #ecedf0,
    "200": #e0e1e6,
    "300": #d4d9e7,
    "400": #c9ccd5,
    "500": #c0c3cd,
    "600": #babdc8,
    "700": #b2b5c1,
    "800": #aaaeba,
    "900": #9ca1ae,
);

$green: (
    "base": #75c718,
);

$pink: (
    "base": #db65cf,
);

$purple: (
    "base": #bf85fd,
);

$orange: (
    "base": #fa5523,
);

$yellow: (
    "base": #ffea17,
);

$blue: (
    "base": #005073,
);

$shades: (
    "black": #000000,
    "white": #ffffff,
    "grey": #f5f6fa,
    "transparent": transparent,
);

$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "red": $red,
    "teal": $teal,
    "grey": $grey,
    "green": $green,
    "pink": $pink,
    "purple": $purple,
    "orange": $orange,
    "yellow": $yellow,
    "blue": $blue,
    "shades": $shades,
);

$box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
