$btn-colors: "primary", "secondary", "red", "teal";

.btn {
    display: inline-block;
    padding: $spacing-unit * 1.2 $spacing-unit * 6;
    font-size: 1.3rem;
    font-family: $font-inter;
    line-height: 2.5rem;
    color: color("grey", "dark");
    background-color: color("grey", "100");
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: none;
    border-radius: $border-radius;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s padding ease-out;

    + .btn {
        margin-left: #{$spacing-unit * 2};
    }
    @include event_attention() {
        background-color: darken(color("grey", "100"), 10);
        text-decoration: none;
        outline-width: 0;
    }

    &--loader {
        position: relative;
        padding-left: #{$spacing-unit * 6};
        padding-right: #{$spacing-unit * 4};

        &:before {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-size: $spacing-unit * 3;
            background-position: #{$spacing-unit * 2} center;
            background-repeat: no-repeat;
            background-image: url("../../images/loader-round--black.svg");
        }
    }

    &--small {
        padding: $spacing-unit $spacing-unit * 2;
    }

    &--transparent {
        background-color: transparent;
        color: inherit;
        @include event_attention() {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
    @each $color in $btn-colors {
        &--transparent-#{$color} {
            background-color: transparent;
            color: color($color, "base");
            @include event_attention() {
                background-color: rgba(color($color, "base"), 0.2);
                color: color($color, "base");
            }
        }
    }

    &--primary {
        background-color: color("primary", "base");
        color: text-color(color("primary", "base")) !important;
        @include event_attention() {
            background-color: color("secondary", "base");
        }
        &.btn--loader:before {
            background-image: url("../../images/loader-round--white.svg");
        }
    }

    &--primary-shades {
        background-color: color("shades", "transparent");
        color: color("shades", "white");
        border: 1px solid color("shades", "white");
        @include event_attention() {
            background-color: color("shades", "white");
            color: color("primary", "dark");
        }
        &.btn--loader:before {
            background-image: url("../../images/loader-round--white.svg");
        }
    }

    &--secondary {
        background-color: color("secondary", "base");
        color: text-color(color("secondary", "base")) !important;
        @include event_attention() {
            background-color: color("primary", "base");
        }
        &.btn--loader:before {
            background-image: url("../../images/loader-round--white.svg");
        }
    }

    &--red {
        background-color: color("red", "dark");
        color: text-color(color("red", "dark")) !important;
        @include event_attention() {
            background-color: color("red", "base");
        }
        &.btn--loader:before {
            background-image: url("../../images/loader-round--white.svg");
        }
    }

    &--teal {
        background-color: color("teal", "dark");
        color: text-color(color("teal", "dark")) !important;
        @include event_attention() {
            background-color: color("teal", "base");
        }
        &.btn--loader:before {
            background-image: url("../../images/loader-round--white.svg");
        }
    }

    &--grey {
        color: color("shades", "white");
        background-color: color("grey", "disabled");
        @include event_attention() {
            background-color: color("secondary", "base");
        }
        &.btn--loader:before {
            background-image: url("../../images/loader-round--white.svg");
        }
    }

    &:disabled,
    &--disabled {
        color: color("shades", "white");
        background-color: color("grey", "disabled");
        border: none;
        cursor: not-allowed;

        &.btn--loader:before {
            background-image: url("../../images/loader-round--black.svg");
        }
    }

    &--rounded {
        padding: 0;
        min-width: 30px;
        border-radius: 30px;
    }

    &--link {
        background-color: transparent;
        padding: 0;
        cursor: pointer;

        @include event_attention() {
            background-color: transparent;
            color: color("secondary", "base");
        }
        &:disabled {
            color: color("grey", "disabled");
            background-color: none;
        }
    }
    &--no-style {
        @extend .btn--link;
        border: 0;
        outline-width: 0;

        @include event_attention() {
            border: 0;
            outline-width: 0;
        }
    }
    &--switch {
        padding-top: $spacing-unit * 2;
        padding-right: $spacing-unit * 2;
        text-align: right;

        @include media-breakpoint-up("large") {
            position: absolute;
            top: $spacing-unit * 2;
            right: $spacing-unit * 3;
            padding: 0;
            text-align: initial;
        }

        .btn {
            padding: 0;
            background-color: transparent;
            color: color("shades", "black");

            @include event_attention() {
                background-color: transparent;
                color: color("secondary", "base");
            }
        }
    }
    &--icon {
        padding: $spacing-unit * 1.5;
        line-height: 0;

        .svg-inline--fa {
            width: 15px;
            height: 15px;
        }
        &-small {
            padding: 8px;

            .svg-inline--fa {
                width: 10px;
                height: 10px;
            }
        }
    }
    &--full {
        width: 100%;
    }
    &--fit {
        height: auto !important;
        max-width: 100% !important;
        white-space: normal !important;
    }
}
.link-secondary {
    color: color("secondary", "base");
    cursor: pointer;

    @include event_attention() {
        background-color: transparent;
        color: color("secondary", "base");
    }
    &:disabled {
        color: color("grey", "disabled");
        background-color: none;
    }
}
