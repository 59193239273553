.flash-message-container {
    display: block;
    position: fixed;
    z-index: 1500;
    top: 0;
    right: 0;
    width: 100%;

    & > * {
        margin-top: 10px;
    }

    & > *:first-child {
        margin-top: 0;
    }

    @include media-breakpoint-up("sm") {
        top: 30px;
        left: 380px;
        width: 40%;
    }
}

.flash-message {
    $self: &;
    box-shadow: $box-shadow;
    margin-top: 10px;
    position: relative;
    line-height: 1.5em;
    padding: $spacing-unit $spacing-unit * 2;
    word-break: break-word;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: opacity 0.75s linear;
    opacity: 1;
    border-radius: $border-radius;
    background-color: color("grey", "dark");
    color: text-color(color("grey", "dark"));
    cursor: default;

    &:hover {
        cursor: pointer;
    }

    &--primary {
        background-color: color("primary", "base");
        color: text-color(color("primary", "base"));
    }

    &--secondary {
        background-color: color("secondary", "base");
        color: text-color(color("secondary", "base"));
    }

    &--error {
        background-color: color("red", "base");
        color: text-color(color("red", "base"));
    }

    &--warning {
        background-color: color("orange", "base");
        color: text-color(color("orange", "base"));
    }

    &--success {
        background-color: color("primary", "base");
        color: text-color(color("primary", "base"));
    }

    &--dark {
        background-color: color("secondary", "dark");
        color: text-color(color("secondary", "dark"));
    }

    &--show {
        opacity: 1;
    }

    &--hide {
        opacity: 0;
    }
}
