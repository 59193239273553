.calendrier {
    &-topbar {
        display: flex;

        &--navigation {
            display: flex;
            flex-grow: 1;
            align-items: center;

            &-date {
                width: 300px;
                color: color("primary", "base");
                font-size: 20px;
                font-weight: bold;
                text-transform: uppercase;
                text-align: center;
                margin: 0 15px 0 15px;
            }
        }

        &--actions {
            margin-left: auto;
        }
    }
}
