.pagination {
    $self: &;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;

    &-link {
        display: block;
        background-color: transparent;
        text-align: center;
        user-select: none;
        border: none;
        white-space: nowrap;
        overflow: hidden;
        padding: 0;
        margin: 0;
        min-width: 30px;
        font-family: $font-inter;
        cursor: pointer;
        outline: 0;

        @include event_attention() {
            color: color("primary", "base");
        }

        #{$self}-item--active & {
            color: color("secondary", "base");
            pointer-events: none;
        }
        #{$self}-item--disabled & {
            pointer-events: none;
        }
        #{$self}-item--previous & {
            .svg-inline--fa {
                font-size: 1.5rem;
                transform: rotate(90deg);
            }
        }
        #{$self}-item--next & {
            .svg-inline--fa {
                font-size: 1.5rem;
                transform: rotate(270deg);
            }
        }
    }
}
