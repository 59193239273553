.paper {
    background-color: color("shades", "white");
    border-radius: $border-radius;

    &-title {
        color: color("shades", "black");
        font-family: $font-inter;
        margin: 0 0 1rem 0;
    }
}
