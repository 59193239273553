@import "./analyse/filters";
@import "./analyse/graph";
@import "./analyse/compare";
@import "./analyse/dashboard";
@import "./analyse/table";
@import "./analyse/graphs";
@import "./analyse/indicator";

#chart_mesurement_id {
    font-size: 1.3rem;
    line-height: 1.8rem;

    &.is-legend {
        font-size: 1.3rem;
    }
    &.is-small {
        font-size: 1rem;
    }
}
