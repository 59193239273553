.financements {
    &__crud {
        .crud_list__container {
            padding: 0;
            box-shadow: none;
        }
    }
    &__list__table-indexation {
        .table__col {
            padding: $spacing-unit;
            text-align: right;
        }
    }
    &__toggle {
        .fa-toggle {
            &-off {
                color: color("grey", "disabled");
            }
            &-on {
                color: color("secondary", "base");
            }
        }
    }
}
