.graphs {
    &__item {
        display: block;
        font-size: 1.3rem;

        .chart__axis__domain__label {
            font-size: 1.3rem;
        }

        &.font-size-small {
            font-size: 1rem;

            .chart__axis__domain__label {
                font-size: 1rem;
            }
        }
    }
    &__progress-bars {
        margin-top: $spacing-unit * 3;

        &__item {
            display: flex;
            align-items: flex-start;
            margin-bottom: $spacing-unit;

            &__label {
                flex-basis: 35%;
            }
            &__bar {
                flex-basis: 55%;
                height: $spacing-unit * 2;
                margin: 0 $spacing-unit;
                background: color("shades", "grey");
                border-radius: $border-radius;

                span {
                    display: block;
                    height: $spacing-unit * 2;
                    background: color("shades", "black");
                    border-radius: $border-radius;
                }
            }
            &__value {
                flex-basis: 10%;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &__dispatch-bar {
        margin-top: $spacing-unit * 3;

        &__progress {
            display: flex;
            height: $spacing-unit * 2;

            &__item {
                height: $spacing-unit * 2;

                &:first-of-type {
                    border-top-left-radius: $border-radius;
                    border-bottom-left-radius: $border-radius;
                }
                &:last-of-type {
                    border-top-right-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                }
            }
        }
        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: $spacing-unit * 2;

            &__item {
                margin-top: $spacing-unit;
                padding-left: $spacing-unit;
                border-left: 4px solid color("shades", "black");

                &.label--inline {
                    border-left-color: transparent;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                }
            }
        }
        &.is-small {
            .graphs__dispatch-bar {
                &__progress {
                    height: $spacing-unit;

                    &__item {
                        height: $spacing-unit;
                    }
                }
                &__list {
                    margin-top: $spacing-unit;

                    &__item__label {
                        padding-right: 3px;

                        &:after {
                            content: " : ";
                        }
                    }
                }
            }
        }
    }
    &__legend-like {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 4px 10px;
        background: color("shades", "grey");

        &.is-relative {
            position: relative;
        }
        &__item {
            display: flex;
            align-items: center;
            margin: 6px 12px;

            &__color {
                display: inline-block;
                height: 12px;
                width: 12px;
                margin-right: 12px;
                border-radius: $border-radius;
            }
            &__label {
                display: inline-block;
            }
        }
    }
    &__legend-taux {
        display: flex;
        justify-content: space-between;
        margin: $spacing-unit;
        padding: $spacing-unit;

        &__item {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding: $spacing-unit;
            background: color("shades", "grey");
            border-radius: $border-radius;

            &__value {
                padding: $spacing-unit / 2 $spacing-unit;
                margin-left: $spacing-unit * 2;
                background: color("shades", "white");
                border-radius: $border-radius;
            }
        }
    }
    &__tooltip-like {
        font-size: 1.5rem;

        & > * {
            margin-bottom: $spacing-unit * 3;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
        &__item {
            display: flex;
            justify-content: space-between;
            padding: 0;
            margin: 0 0 $spacing-unit;

            &__separator {
                flex-grow: 1;
                margin: 0 $spacing-unit 4px;
                border-bottom: 1px dashed color("grey", "base");
            }
            &:first-of-type {
                font-size: 1.7rem;
            }
        }
    }
}
