.crud-bulk-actions {
    display: flex;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0 $spacing-unit * 2;
    margin-left: -$spacing-unit * 2;
    height: 0;
    width: 100%;
    min-height: 0;
    overflow-y: hidden;
    background-color: color("shades", "grey");
    border-radius: $border-radius;
    z-index: 3;
    transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, min-height 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        margin-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &--open {
        min-height: 62px;
        margin-top: -62px;
        padding: $spacing-unit/2 $spacing-unit * 2;
    }
    &__info {
        &.has-actions {
            .btn {
                @extend .btn--link;
                position: relative;
                margin: 0 $spacing-unit * 2 0 0;
                padding-right: $spacing-unit * 2;
                color: color("secondary", "base");

                &:hover {
                    color: color("primary", "base");
                }
                &:not(:last-of-type) {
                    &:after {
                        content: "|";
                        position: absolute;
                        right: 0;
                        color: color("shades", "black");
                    }
                }
            }
        }
    }
}
