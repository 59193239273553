@mixin event_attention() {
    &:hover,
    &:active,
    &:focus {
        @content;
    }
}

@mixin event_hover() {
    &:hover {
        @content;
    }
}

@mixin event_focus() {
    &:focus {
        @content;
    }
}

@mixin event_active() {
    &:active {
        @content;
    }
}
