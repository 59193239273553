.chart__hover {
    fill: transparent;

    &[data-hover] {
        fill: rgba(0, 0, 0, 0.02);
    }
}
.chart__backgrounds {
    fill: rgba(0, 0, 0, 0.02);
}
