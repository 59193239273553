.box {
    position: relative;
    padding: #{$spacing-unit * 2} #{$spacing-unit * 3} #{$spacing-unit * 3};
    background: color("shades", "white");
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}

.page {
    @extend .box;
    min-height: 200px;
    margin: 20px 30px;

    &-header {
        margin-bottom: #{$spacing-unit * 2};

        @include media-breakpoint-up("medium") {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    &-title {
        margin: 0;
        color: color("secondary", "base");
    }
    &-download {
        text-align: right;
        cursor: pointer;
    }
    &-markdown {
        min-height: calc(100vh - #{$topbar-height} - #{$spacing-unit * 5});

        h1 {
            margin: 0 0 $spacing-unit * 2;
            color: color("secondary", "base");
        }
        a {
            color: color("secondary", "base");

            &:link,
            &:visited,
            &:hover,
            &:active {
                color: color("secondary", "base");
            }
            &:hover {
                color: color("primary", "base");
            }
        }
    }
}
