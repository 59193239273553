a {
    text-decoration: none;
    cursor: pointer;

    &:link {
        color: color("shades", "black");
    }
    &:visited {
        color: color("shades", "black");
    }
    &:hover {
        color: color("secondary", "base");
    }
    &:active {
        color: color("secondary", "base");
    }
}
