.container {
    // Fit content
    display: inline-block;
    padding-right: $spacing-unit * 3;
    padding-left: $spacing-unit * 3;
    min-width: 100%;
}

.container-content-width {
    float: left;
}
