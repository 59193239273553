.chart__label {
    &--light {
        fill: color("grey", "light");
    }
    &--inner {
        fill: color("shades", "white");
    }
    &_total {
        fill: color("red", "base");

        &--light {
            fill: color("shades", "white");
        }
    }
}
