$homeItemSize: 250px;
$homeItemMargin: 7px;
$homeLogoSize: 90px;

.home-links {
    width: calc(2 * #{$homeItemSize} + #{$homeItemMargin});
    margin: auto;
    display: grid;
    grid-template-columns: $homeItemSize $homeItemSize;
    grid-column-gap: $homeItemMargin;
    grid-row-gap: $homeItemMargin;
    position: relative;

    &-item {
        height: $homeItemSize;
        padding: 0 60px;
        background-color: color("primary", "base");
        color: color("shades", "white");
        font-size: 18px;
        text-transform: uppercase;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            background-color: color("primary", "dark");
            font-weight: bold;
        }
    }

    &:before {
        content: " ";
        background-image: url("../../images/logo-forex-small.png");
        width: $homeLogoSize;
        height: $homeLogoSize;
        position: absolute;
        top: calc(#{$homeItemSize} + #{$homeItemMargin / 2} - #{$homeLogoSize} / 2);
        left: calc(#{$homeItemSize} + #{$homeItemMargin / 2} - #{$homeLogoSize} / 2 + 5px);
    }
}
