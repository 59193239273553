.financements {
    &__table {
        position: relative;
        box-shadow: $box-shadow;
        z-index: 2;

        .table {
            border: 1px solid color("grey", "300");

            &-sort-label {
                padding: 0;
                font-weight: normal;
                white-space: normal;

                &:hover,
                &:active,
                &:focus {
                    background: none;
                }
                .fa-stack {
                    width: 20px;
                }
            }
            &__head {
                .table__row {
                    height: 75px;
                }
                .table__col {
                    padding: $spacing-unit;
                    font-weight: normal;
                    color: color("grey", "dark");

                    @include media-breakpoint-up("large") {
                        &:nth-of-type(1) {
                            width: 1%;
                            text-align: center;
                        }
                    }
                }
            }
            &__body {
                .table__row {
                    height: 75px;
                    border: 0;
                    background-image: linear-gradient(to right, color("grey", "dark") 33%, color("shades", "white") 0%);
                    background-position: top;
                    background-size: 4px 2px;
                    background-repeat: repeat-x;

                    &:nth-child(odd) {
                        background-color: color("shades", "grey");

                        &:hover {
                            background-color: rgba($color: #000000, $alpha: 0.07);
                        }
                    }
                }
                .table__col {
                    padding: $spacing-unit;
                    color: color("shades", "black");
                    white-space: pre-line;

                    &:nth-of-type(1) {
                        text-align: center;
                        color: color("secondary", "base");
                    }
                }
            }
            &__col {
                &:nth-of-type(1) {
                    background-image: linear-gradient(
                        to bottom,
                        color("grey", "dark") 33%,
                        color("shades", "white") 0%
                    );
                    background-position: right;
                    background-size: 2px 4px;
                    background-repeat: repeat-y;
                }
            }
        }
        &__contreparties {
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
        }
    }

    &__analyse {
        &-form {
            display: flex;
            align-items: center;
            overflow: visible;
        }

        &-table {
            max-width: 50% !important;
            box-shadow: $box-shadow;

            .table__head .table__col,
            .table__body .table__col {
                min-width: 150px;
                padding: 10px 15px;
            }

            .table__head .table__col {
                color: #ffffff;
            }

            .table__body .table__col {
                color: #000000;
            }
        }

        &-button {
            margin-left: 50px;
        }
    }
}
