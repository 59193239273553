.crud-per-page-selector {
    display: flex;
    align-items: center;

    &__label {
        display: block;
        margin-right: $spacing-unit / 2;
        white-space: nowrap;
    }
}
