.field {
    &-control {
        &__select {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            color: inherit;
            background-color: transparent;
            border: none;
            border-radius: $border-radius;

            &::placeholder {
                color: color("grey", "base");
                font-weight: bold;
            }

            &:focus {
                outline: 0;
            }
            &.disabled {
                color: color("grey", "disabled-text");
            }
            .svg-inline--fa {
                position: absolute;
                right: $spacing-unit * 2;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;

                &.up {
                    top: calc(50% - 6px);
                    transform: rotate(180deg);
                }
            }
        }

        &-select {
            &__input {
                @extend .field-control__input;

                padding: $spacing-unit * 1.5 $spacing-unit * 2;
                padding-right: $spacing-unit * 2 + $spacing-unit * 2;
                z-index: 5;

                &:hover {
                    cursor: pointer;
                }
            }

            &__selected_item {
                position: absolute;
                top: 50%;
                padding: $spacing-unit $spacing-unit * 2;
                transform: translateY(-50%);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 85%;

                &.disabled {
                    color: color("grey", "disabled-text");

                    &:hover {
                        cursor: not-allowed;
                    }
                }
            }

            &__list {
                display: block;
                position: absolute;
                top: 100%;
                left: 50%;
                max-height: 210px;
                min-width: calc(100% + 2px);
                padding: 0;
                margin: 0;
                background-color: color("shades", "white");
                border: 1px solid color("secondary", "base");
                border-radius: $border-radius;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                transform: translateX(-50%);
                overflow-y: auto;
                z-index: 10;
            }

            &__item {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                min-height: 45px;
                padding: $spacing-unit $spacing-unit * 2;
                color: color("shades", "black");
                text-decoration: none;
                font-weight: normal;
                border-top: 1px solid color("secondary", "base");
                list-style-type: none;
                overflow: hidden;
                cursor: pointer;

                &.highlighted {
                    background-color: color("grey", "light");
                }
                &:first-of-type {
                    border-top: 0;
                }
            }
        }
    }
}

.form-contrast {
    .field {
        &-control {
            &__select {
                .svg-inline--fa {
                    color: color("shades", "white");
                }
            }
            &-select {
                &__list {
                    border-color: color("shades", "white");
                    box-shadow: $box-shadow;
                }
                &__selected_item {
                    color: color("shades", "white");

                    &.disabled {
                        color: color("primary", "base");
                        background-color: transparent;
                    }
                }
            }
        }
    }
}
