.field {
    &__table {
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;

        & > table {
            width: 100%;
            max-width: 100%;
            border-collapse: collapse;
            // white-space: nowrap;

            td {
                padding: 0;
                text-align: center;
                border: 1px solid transparent;
            }
            thead {
                tr {
                    background: color("secondary", "base");
                    color: color("shades", "white");

                    th {
                        padding: $spacing-unit 15px;
                        border: 1px solid color("shades", "white");

                        &.taux {
                            min-width: 125px;
                        }
                    }
                }
            }
            tfoot {
                th {
                    position: relative;
                    line-height: 2em;

                    &.error {
                        color: color("red", "base");
                    }
                    .percentage {
                        position: absolute;
                        position: absolute;
                        right: 19px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                tr:last-of-type {
                    background: color("secondary", "base");
                    color: color("shades", "white");

                    th {
                        padding: $spacing-unit / 2;
                        border: 1px solid color("shades", "white");
                    }
                }
            }
            tbody {
                tr {
                    &:nth-child(odd) {
                        background: rgb(250, 250, 250);

                        .field-control__adornment .percentage {
                            background: rgb(250, 250, 250);
                        }
                    }
                    &:nth-child(even) {
                        background: rgb(245, 245, 245);

                        .field-control__adornment .percentage {
                            background: rgb(245, 245, 245);
                        }
                    }
                    &:hover {
                        background: rgb(240, 240, 240);

                        .field-control__adornment .percentage {
                            background: rgb(240, 240, 240);
                        }
                    }

                    td {
                        height: 30px;
                        border-color: color("shades", "white");

                        &.error {
                            border-width: 2px;
                            border-color: color("red", "base");
                        }
                        .echeancier-disabled {
                            color: color("grey", "disabled-text");
                            cursor: not-allowed;
                        }

                        .field {
                            margin: 0;

                            &-control {
                                border: none;

                                &-select {
                                    &__input {
                                        padding: 0;
                                        text-align: center;
                                    }
                                    &__list {
                                        top: calc(100% + 8px);
                                    }
                                    &__selected_item {
                                        padding: 0;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                    }
                                }
                                &__select {
                                    .svg-inline--fa {
                                        right: 5px;
                                    }
                                }
                                &__input {
                                    padding: 0;
                                    padding-left: $spacing-unit;
                                    text-align: center;

                                    &[type="date"] {
                                        padding: 0;
                                        padding-left: 40px;
                                    }
                                    &[type="number"] {
                                        padding-right: 9px;
                                        padding-left: 23px;
                                    }
                                }
                                &__adornment .percentage {
                                    padding: 4px 10px;
                                }
                                &.disabled {
                                    .field-control__select {
                                        .svg-inline--fa {
                                            display: none;
                                        }
                                    }
                                }
                            }
                            &__label {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        &__add-link {
            margin: 0 0 5px;
            text-align: right;

            button {
                color: color("secondary", "base");
            }
        }
        &__actions {
            width: 100%;

            td {
                width: 25% !important;
                border: 1px solid transparent;
                background: color("shades", "white");

                button {
                    width: 100%;
                }
            }
        }
        &__errors {
            color: color("red", "base");
        }
        .field__feedback {
            display: none;
        }
        .field-control.error {
            border: none;
        }
        &.has-actions {
            table {
                tbody {
                    tr {
                        td:last-of-type {
                            width: 100px;
                            background: color("shades", "white");
                        }
                    }
                }
            }
        }
        &.no-move {
            table {
                tbody {
                    tr {
                        td:last-of-type {
                            width: 50px;
                        }
                    }
                }
            }
        }
    }
}
