.analyse_indicator {
    display: flex;

    &_wrapper {
        position: relative;
        margin-left: calc(2 * #{$spacing-unit});
    }

    &_icon {
        display: block;
        position: absolute;
        bottom: 0;

        &--ok {
            color: color("teal", "dark");
        }
        &--ko {
            color: color("red", "base");
        }
        &--equal {
            width: 12px;
            color: color("primary", "base");
        }
    }
}
