.financements {
    &__actions {
        position: absolute;
        display: flex;
        max-width: 100%;
        padding: $spacing-unit;
        top: $spacing-unit / 2;
        left: 50%;
        transform: translateX(-50%);
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        background: color("shades", "white");
    }
}
