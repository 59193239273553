.donnees-marches {
    &_devises {
        padding: $spacing-unit * 2;
        background-color: color("shades", "white");
        border-radius: $border-radius;
        box-shadow: $box-shadow;

        .chart-tooltip__item {
            span {
                display: flex;
                align-items: center;
            }
        }
    }
}
