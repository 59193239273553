.financements {
    &__filters {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        align-content: stretch;
        margin-top: $spacing-unit * 2;
        margin-bottom: 0;
        border: 1px solid color("grey", "300");
        border-bottom: 0;
        border-top-left-radius: $border-radius;

        @include media-breakpoint-up("large") {
            margin-left: 395px;
        }

        & > div {
            flex: calc(100% / 3);
            padding: $spacing-unit * 2;
            border-right: 1px solid color("grey", "300");
            background: color("shades", "grey");
            color: color("grey", "dark");
            font-size: 1.5rem;
            text-align: center;

            &:hover {
                cursor: pointer;
                color: color("secondary", "base");
            }
            &.current {
                background: none;
                color: color("secondary", "base");
            }
            &:first-of-type {
                border-top-left-radius: $border-radius;
            }
            &:last-of-type {
                border-right: 0;
            }
        }
    }
}
