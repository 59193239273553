$iefix: 0.01px;

@mixin grid($grid-number: 1) {
    & > * {
        width: calc(100% / #{$grid-number} - #{$iefix});
    }

    @each $affix, $size in $grid-gutters {
        &.grid--has-gutter#{$affix} {
            margin-right: -$size / 2;
            margin-left: -$size / 2;
            margin-bottom: -$size / 2;

            & > * {
                width: calc(100% / #{$grid-number} - #{$size} - #{$iefix});
                margin-right: $size / 2;
                margin-left: $size / 2;
                margin-bottom: $size / 2;
            }
        }
    }
}

.grid {
    $self: &;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > * {
        box-sizing: border-box;
        min-width: 0;
        min-height: 0;
    }

    @each $affix, $size in $grid-gutters {
        &--has-gutter#{$affix} > * + * {
            margin-left: calc(#{$size} - #{$iefix});
        }
    }

    &--reverse {
        flex-direction: row-reverse;
    }

    &__auto {
        & > * {
            flex: 1 1 0%;
        }
    }

    // Constructing grids : will be compiled in CSS
    @for $i from 1 through $grid-number-cols {
        &__#{$i} {
            @include grid(#{$i});
        }
    }
    @for $i from 1 through $grid-number-cols {
        &__#{$i} {
            @for $breakpointIndex from 1 through length($breakpoints) - 1 {
                $keys: map-keys($breakpoints);
                $breakpoint: nth($keys, $breakpointIndex);
                @include media-breakpoint-up($breakpoint) {
                    &--#{$breakpoint} {
                        @include grid(#{$i});
                    }
                }
            }
        }
    }

    @for $i from 1 through $grid-number-cols {
        & > #{$self}-col__#{$i} {
            width: calc(100% / #{$i} - #{$iefix});
        }
        @each $affix, $size in $grid-gutters {
            &.grid--has-gutter#{$affix} {
                & > #{$self}-col__#{$i} {
                    width: calc(100% / #{$i} - #{$size} - #{$iefix});
                }
            }
        }
    }

    @for $i from 1 through $grid-number-cols {
        @for $breakpointIndex from 1 through length($breakpoints) - 1 {
            $keys: map-keys($breakpoints);
            $breakpoint: nth($keys, $breakpointIndex);
            @include media-breakpoint-up($breakpoint) {
                & > #{$self}-col__#{$i}--#{$breakpoint} {
                    width: calc(100% / #{$i} - #{$iefix});
                }
                @each $affix, $size in $grid-gutters {
                    &.grid--has-gutter#{$affix} {
                        & > #{$self}-col__#{$i}--#{$breakpoint} {
                            width: calc(100% / #{$i} - #{$size} - #{$iefix});
                        }
                    }
                }
            }
        }
    }
}
