.chart__axis {
    .domain {
        stroke: transparent;
    }

    .label,
    &.legend {
        fill: color("grey", "dark");
        font-family: $font-inter;
    }
    .tick {
        line {
            stroke: transparent;

            &.dotted {
                stroke: color("grey", "base");
                stroke-dasharray: 3px;
            }
            &.zero {
                stroke: color("grey", "base");
            }
        }
    }
    &__light {
        .tick {
            text {
                fill: color("grey", "dark");
            }
        }
    }
}
