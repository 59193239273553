.loader-backdrop {
    background: color("shades", "black");
    opacity: 0.2;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
}

.loader-backdrop-wrapper {
    position: relative;
    min-height: 120px;
    &.loader-backdrop-wrapper--full {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: color("shades", "white");
    flex-direction: column;
    .loader-backdrop-wrapper > & {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;

        @supports (position: sticky) {
            position: sticky;
            max-height: 100vh;
        }
    }
}

.loader {
    display: inline-block;
    width: 64px;
    height: 64px;
    border: 2px solid transparent;
    border-top-color: #005073;
    border-radius: 50%;
    animation: container-rotate 2s linear infinite;
    &--small {
        width: 36px;
        height: 36px;
    }

    &--big {
        width: 80px;
        height: 80px;
    }
    &:before {
        content: "";
        position: absolute;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: #0871b8;
        animation: container-rotate 3s linear infinite;
    }
    &:after {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        right: 10px;
        bottom: 10px;
        border-radius: 50%;
        border: 2px solid transparent;
        border-top-color: #2babe2;
        animation: container-rotate 2s ease-in-out infinite;
    }
    &-text {
        text-shadow: 1px 1px 5px color("shades", "black");
    }
}

@keyframes container-rotate {
    to {
        transform: rotate(360deg);
    }
}
