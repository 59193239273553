.analyse_dashboard {
    &_indicator {
        padding: $spacing-unit;
        background: color("grey", "50");

        &_title,
        &_amount {
            text-align: center;
        }
        &_title {
            margin: 0;
            font-size: 1.5rem;
            line-height: 3rem;
            color: color("shades", "black");
        }
        &_amount {
            margin-top: 0;
            font-weight: bold;
            font-size: 2.4rem;
            line-height: 3rem;
            color: color("primary", "dark");
        }
    }
    &_pourcentage {
        .table__body {
            text-align: center;

            .table__row {
                border: 0;
            }
            .table__col {
                padding: $spacing-unit;
            }
            th.table__col {
                font-size: 1.3rem;
                line-height: 1.7rem;
                color: color("shades", "black");
            }
            td.table__col {
                font-size: 2em;
                font-weight: bold;
                font-size: 2.4rem;
                line-height: 3rem;
                color: color("primary", "dark");
                background-color: color("shades", "white");
            }
        }
    }
    &_list {
        & > * {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            & > div {
                margin-bottom: $spacing-unit;

                &:first-child {
                    color: color("shades", "black");
                }
                &:last-child {
                    font-size: 1.1em;
                    color: color("primary", "dark");
                }
            }
        }
        .dotted {
            flex-grow: 1;
            margin-right: $spacing-unit;
            margin-left: $spacing-unit;
            border-bottom: 1px dotted color("grey", "dark");
        }
    }
    &_link {
        margin-bottom: -18px;
        text-align: right;

        &-absolute {
            position: absolute;
            right: $spacing-unit * 3;
            top: $spacing-unit * 3;
        }
    }
    &_time-labels {
        display: flex;
        justify-content: space-between;
    }
}
