.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
    margin: 0 0 $spacing-unit;
    font-weight: normal;
    font-family: $font-inter;
    line-height: 17px;
}

.h1,
h1 {
    font-size: 1.8em;
    font-weight: bold;
    line-height: 22px;
}

.h2,
h2 {
    font-size: 1.5em;
    line-height: 20px;
}

.h3,
h3 {
    font-size: 1.5em;
}

.h4,
h4,
.h5,
h5,
.h6,
h6 {
    font-size: 1em;
}
