.layout-minimal {
    &-root {
        background: color("shades", "white");

        & > * {
            min-height: 100vh;
        }

        @include media-breakpoint-up("medium") {
            display: flex;
            flex-direction: row;
            align-items: stretch;
            align-content: stretch;
            justify-content: space-between;
        }
    }
    &-main {
        position: relative;
        background: color("shades", "white");

        @include media-breakpoint-up("medium") {
            flex-basis: 30vw;
        }

        &-logo {
            padding-top: 27vh;
            text-align: center;
        }
        &-content {
            max-width: 400px;
            margin: 0 auto;
            padding: $spacing-unit * 3;

            a {
                color: color("secondary", "base");

                &.btn {
                    color: color("shades", "white");
                    line-height: 2rem;
                }
            }
            .btn {
                height: $spacing-unit * 4;
                margin-top: $spacing-unit * 2;
                font-size: 1.3rem;
                text-align: center;
                white-space: nowrap;
                overflow: hidden;
                padding: 9px 50px;
            }
        }
        &-copyright {
            position: absolute;
            bottom: $spacing-unit * 2;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &-image {
        display: none;

        @include media-breakpoint-up("medium") {
            display: block;
            flex-basis: 70vw;
            background-image: url("../../images/minimal-image.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    &-form {
        text-align: center;
    }
}
