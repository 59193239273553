.field {
    &__label {
        position: absolute;
        top: 50%;
        left: 20px;
        font-size: 1.3rem;
        color: color("grey", "dark");
        transform: translateY(-50%);
        transition: 0.2s all;
        pointer-events: none;

        &.force-in {
            top: 50% !important;
            left: 15px !important;
            font-size: 1.3rem !important;
        }
        &--up {
            margin-top: -5px;
        }
    }
}

.label-on-top {
    top: 5px;
    left: 10px;
    padding: 0 10px 3px;
    font-size: 1.2rem;
    line-height: 1.2rem;
    background: color("shades", "white");
}

// Fix sass @extend Firefox issue (@extend .label-on-top)
.field-control-file.has-file,
.field-control-checkbox,
.field-control-radio,
.field__repeater-fields:not(:empty),
.field-control__input:focus,
.field-control__input:not(:placeholder-shown),
.field-control__textarea:focus,
.field-control__textarea:not(:placeholder-shown) {
    & + .field__label {
        top: 5px;
        left: 10px;
        padding: 0 10px 3px;
        font-size: 1.2rem;
        line-height: 1.2rem;
        background: color("shades", "white");
    }
}
.field-control__input:-webkit-autofill,
.field-control__textarea:-webkit-autofill {
    & + .field__label {
        top: 5px;
        left: 10px;
        padding: 0 10px 3px;
        font-size: 1.2rem;
        line-height: 1.2rem;
        background: color("shades", "white");
    }
}
.field-control__input:-moz-autofill,
.field-control__textarea:-moz-autofill {
    & + .field__label {
        top: 5px;
        left: 10px;
        padding: 0 10px 3px;
        font-size: 1.2rem;
        line-height: 1.2rem;
        background: color("shades", "white");
    }
}

.form-contrast {
    .field__label {
        color: color("grey", "disabled");
    }
    .label-on-top {
        color: color("shades", "white");
        background: color("secondary", "base");
    }
    .field-control-file.has-file,
    .field-control-checkbox,
    .field-control-radio,
    .field__repeater-fields:not(:empty),
    .field-control__input:focus,
    .field-control__input:not(:placeholder-shown),
    .field-control__textarea:focus,
    .field-control__textarea:not(:placeholder-shown) {
        & + .field__label {
            color: color("shades", "white");
            background: color("secondary", "base");
        }
    }
    .field-control__input:-webkit-autofill,
    .field-control__textarea:-webkit-autofill {
        & + .field__label {
            color: color("shades", "white");
            background: color("secondary", "base");
        }
    }
    .field-control__input:-moz-autofill,
    .field-control__textarea:-moz-autofill {
        & + .field__label {
            color: color("shades", "white");
            background: color("secondary", "base");
        }
    }
}
