.appbar {
    display: flex;
    width: 100%;
    background-color: color("shades", "white");
    color: color("shades", "grey");
    z-index: 200;
    align-items: stretch;
    align-content: stretch;

    &__logo {
        width: $sidebar-width;
        padding: #{$spacing-unit * 2} #{$spacing-unit * 3};
        background-color: color("shades", "white");
        z-index: 200;

        a {
            display: block;

            img {
                height: 47px;
                width: auto;
                object-fit: "contain";
                vertical-align: middle;
            }
        }
    }

    &__toolbar {
        display: flex;
        position: relative;
        height: $topbar-height;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;
        background-color: color("secondary", "base");
        z-index: 200;

        &-admin,
        &-user {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-grow: 1;
            padding: #{$spacing-unit * 2} #{$spacing-unit * 3};
            padding-right: $spacing-unit;

            & > * {
                display: flex;
                align-items: center;
                justify-content: center;
                user-select: none;
                padding-right: 0;
                font-weight: bold;
                border: none;
                cursor: pointer;

                &:not(:first-child):before {
                    content: "|";
                }
            }
        }
        &-admin {
            justify-content: flex-start;

            & > * {
                padding-right: #{$spacing-unit * 3};
            }
        }
        &-link {
            padding-right: $spacing-unit * 2;
            color: color("shades", "white");

            &:link,
            &:visited,
            &:hover,
            &:active {
                color: color("shades", "white");
            }
        }

        &__client {
            color: color("shades", "white");

            &-logo {
                height: calc(#{$logo-height + 8px});
                margin: 0 #{$spacing-unit * 2};
                padding: $border-radius;
                border-radius: $border-radius;
                background-color: white;

                img {
                    height: $logo-height;
                    width: auto;
                    object-fit: "contain";
                    vertical-align: middle;
                }
            }
            &-name {
                margin-right: #{$spacing-unit * 2};
            }
            &-switch {
                form {
                    @extend .form-contrast;
                    margin-right: $spacing-unit * 2;

                    .field__label {
                        color: color("shades", "white") !important;
                    }
                }
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;

        padding: 0 1.8rem;
        margin: 0;
        height: 100%;

        text-decoration: none;

        font-family: $font-inter;
        font-weight: bold;
        color: color("grey", "dark");
        color: white;
        background-color: transparent;
        border: none;
        cursor: pointer;

        &--active {
            color: color("primary", "base");
            color: white;
        }

        @include event_attention() {
            @extend .appbar__item--active;
            border: none;
            outline: none;
        }
    }

    &-dropdown {
        position: relative;
        height: 100%;
        &__button {
            @extend .appbar__item;
            font-weight: bold;
            font-family: $font-inter;

            .svg-inline--fa {
                margin-top: 3px;
                font-size: 0.6rem;

                &.up {
                    transform: rotate(180deg);
                }
            }
        }

        &__menu {
            position: absolute;
            z-index: 100;
            top: calc(#{$topbar-height} - #{$spacing-unit * 3});
            right: 15px;
            display: none;
            width: 100%;
            min-width: 270px;
            background-color: color("shades", "white");
            overflow-y: auto;
            list-style: none;
            border-radius: $border-radius;
            box-shadow: $box-shadow;

            &--show {
                display: block;
            }
        }

        &__item {
            display: flex;
            width: 100%;
            padding: $spacing-unit * 2;
            margin: 0;
            border: 0;
            font-weight: normal;
            background-color: transparent;
            align-items: center;
            text-decoration: none;
            cursor: pointer;
            overflow: hidden;
            justify-content: space-between;
            border-left: 4px solid transparent;

            &--active {
                border-left: 4px solid color("secondary", "base");
                background-color: color("shades", "grey");
                color: color("shades", "black");
            }

            @include event_attention() {
                @extend .appbar-dropdown__item--active;
            }
        }

        &__divider {
            height: 0;
            overflow: hidden;
            border-top: 1px solid color("grey", "100");
        }
    }
    &__stripe {
        padding-bottom: $spacing-unit * 8;
        margin-bottom: -$spacing-unit * 8;
        background-color: color("secondary", "base");
    }
}

.appbarSecondary {
    @extend .appbar;
    background-color: color("primary", "base");
    color: color("shades", "white");
    height: 54px;
    padding: 0 1rem;
    display: flex;

    &__toolbar {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        flex-grow: 1;
    }

    &__item {
        display: block;
        padding: 0 1.8rem;
        margin: 0;
        height: 100%;
        text-decoration: none;
        color: inherit;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &--active {
            background-color: color("shades", "white");
            color: color("primary", "base");
        }
        @include event_attention() {
            @extend .appbarSecondary__item--active;
        }
    }
}
