.table-fiche {
    .table__col {
        padding: $spacing-unit;

        &:first-child {
            text-align: left;
            color: color("grey", "dark");
        }
        &:last-child {
            color: color("shades", "black");
        }
        .btn,
        .btn--link {
            color: color("secondary", "base");
        }
    }
}
.table-fiche-financement {
    .table__foot .table__col {
        font-weight: bold;
        color: color("shades", "black");
    }
}
.table-fiche-societes {
    .table__head {
        .table__coll {
            font-weight: normal;
            color: color("grey", "dark");
        }
    }
    .table__col {
        text-align: center;
        color: color("shades", "black");

        .btn--link {
            color: color("secondary", "base");
        }
    }
}
