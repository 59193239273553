.financements {
    &__breadcrumbs {
        margin: 0;
        padding: 0 0 0 $spacing-unit * 3;
        color: color("shades", "white");

        li {
            display: inline-block;

            a,
            span {
                margin-left: $spacing-unit * 2;
                margin-right: $spacing-unit * 2;
            }
            span {
                color: color("grey", "400");
            }
            a,
            .link {
                color: color("shades", "white");
                cursor: pointer;
            }
        }
    }
}
