.table-crud {
    .btn--link {
        color: color("secondary", "base");
    }
    .table__head {
        .table__col {
            padding: $spacing-unit;
            padding-left: $spacing-unit / 2;
            font-weight: normal;
            color: color("grey", "dark");
        }
        .table-sort-label {
            display: flex;
            align-items: center;
            font-weight: normal;

            @include event_attention() {
                background-color: transparent;
            }
        }
    }
    .table__body {
        .table__row {
            border: 0;

            &:first-child {
                border: 0;
            }
            &--clickable:hover {
                background-color: color("shades", "grey");
            }
        }
        .table__col {
            padding: $spacing-unit;
            color: color("shades", "black");
        }
    }
}
