.bg {
    @each $colorName, $color in $colors {
        @if map-has-key($color, "base") {
            &--#{$colorName} {
                background-color: map_get($color, "base");
                color: text-color(map_get($color, "base"));
            }
        }
    }
}

.text-color {
    @each $colorName, $color in $colors {
        @if map-has-key($color, "base") {
            &--#{$colorName} {
                color: map_get($color, "base");
            }
        }
    }
}
