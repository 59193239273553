.form {
    &-toolbar {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        padding-top: #{$spacing-unit * 2};
        margin-top: #{$spacing-unit * 2};
    }
    &-contrast {
        background: color("secondary", "base");
    }
}
