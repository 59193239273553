::selection {
    background: color("secondary", "base");
    background: rgba(color("secondary", "base"), 0.3);
}

::-moz-selection {
    background: color("secondary", "base");
    background: rgba(color("secondary", "base"), 0.3);
}

* {
    -webkit-tap-highlight-color: rgba(color("secondary", "base"), 0.2);
}
