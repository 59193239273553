.toolbar {
    margin-top: #{$spacing-unit * 2};

    @include media-breakpoint-up("medium") {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;
    }

    & > * {
        margin-right: $spacing-unit;

        @include media-breakpoint-up("medium") {
            margin-left: $spacing-unit;
            margin-right: 0;
        }
    }
    .btn {
        color: color("shades", "black");

        &:hover {
            color: color("secondary", "base");
        }
        &:disabled {
            color: color("grey", "disabled");
            background: none;

            &:hover {
                color: color("grey", "disabled");
            }
        }

        & + .btn {
            @include media-breakpoint-down("medium") {
                margin-left: 0;
            }
        }
    }
}
