.financements {
    &__echeancier {
        &__data {
            position: relative;
            min-height: 120px;

            &-header {
                margin: 0;
            }
            table {
                table-layout: fixed;
                text-align: left !important;
                border-style: none;

                th {
                    font-weight: bold !important;
                }
                .table__row {
                    border: 0 !important;
                }
                .table__col {
                    padding: 5px !important;
                    color: color("shades", "black");
                }
            }
        }
        &__toolbar {
            @include media-breakpoint-up("medium") {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
            }
        }
        &__table {
            @extend .field__table;
            position: relative;
            min-height: 120px;

            table {
                thead {
                    tr {
                        height: 60px;
                    }
                }
            }
        }
        &__standart,
        &__ligne-treso,
        &__ligne-conso {
            min-inline-size: auto;
        }
    }
}
