.field {
    &-control {
        &-file {
            display: flex;
            flex-wrap: nowrap;
            width: 100%;

            &__input {
                border: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                opacity: 0;
                z-index: 1;
            }

            &__preview {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                align-content: space-around;
                flex-grow: 1;
            }

            &__img {
                max-height: 60px;
                max-width: 100%;
                margin: 15px $spacing-unit $spacing-unit;
            }

            &__name {
                margin: 0 15px;
                text-align: center;
                z-index: 10;
            }

            &__info {
                margin-left: $spacing-unit;
            }

            &__btn {
                @extend .btn;
                display: block;
                margin: 0 auto;
                width: auto;

                & + & {
                    margin: 0 auto;
                    margin-top: $spacing-unit;
                }
            }
        }
    }
}
.file-field-control > .field-control {
    border-color: transparent;

    .field-control-file__preview {
        @extend .field-control;
    }
}
