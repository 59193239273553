.field {
    &-control {
        &__input {
            display: block;
            width: 100%;
            height: 100%;
            padding: 15px 15px 15px $spacing-unit * 2;
            font-size: 1.3rem;
            line-height: 1.7rem;
            background-color: transparent;
            border: none;

            &::placeholder {
                color: color("shades", "white");
            }
            &:focus {
                outline: 0;

                &::placeholder {
                    color: color("grey", "dark");
                }
            }
            &[type="date"] {
                padding: 13px 15px 13px $spacing-unit * 2;
            }
        }
    }
}

.form-contrast {
    .field-control__input {
        color: color("shades", "white");

        &::placeholder {
            color: color("secondary", "base");
        }
        &:focus {
            &::placeholder {
                color: color("grey", "dark");
            }
        }
        &:focus,
        &:not(:placeholder-shown),
        &:-webkit-autofill {
            color: color("shades", "white");
        }
        &.disabled,
        &:disabled {
            color: color("primary", "base");
        }
        option {
            color: color("shades", "black");
        }
        &[type="date"] {
            color: color("shades", "white");
        }
    }
    ::-webkit-calendar-picker-indicator {
        filter: invert(1); // chrome calendar color to white
    }
}
