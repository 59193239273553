.societe,
.contrepartie {
    &__btn-back {
        color: #9191a4 !important;

        &:hover {
            color: #ffffff !important;
            background-color: #dc2321;
        }
    }
}
