.font {
    &-size {
        &--small {
            font-size: 0.8rem !important;
        }
    }
    &-weight {
        &--bold {
            font-weight: bold !important;
        }
    }
    &-style {
        &--italic {
            font-style: italic !important;
        }
    }
}

.textalign {
    &--center {
        text-align: center !important;
    }
    &--right {
        text-align: right !important;
    }
    &--left {
        text-align: left !important;
    }
}

.textspace {
    &--nowrap {
        white-space: nowrap !important;
    }
    &--pre {
        white-space: pre !important;
    }
}

.textcolor {
    &--primary {
        color: color("primary", "base") !important;
    }
    &--secondary {
        color: color("secondary", "base") !important;
    }
    &--muted {
        color: color("grey", "base") !important;
    }
    &--black {
        color: color("shades", "black") !important;
    }
}
